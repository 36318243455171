import { render, staticRenderFns } from "./GigDisclosure.vue?vue&type=template&id=a27452ce&"
import script from "./GigDisclosure.vue?vue&type=script&lang=js&"
export * from "./GigDisclosure.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports