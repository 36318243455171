<template>
  <div class="disclosure">
    <h1>Gig Ops Charging</h1>
    <h2>Charging Plan Terms and Conditions</h2>
    <p class="primary-color">
      <strong>Effective as of 4/22/2020</strong>
    </p>
    <p>
      This charging plan is made available to you as part of a collaboration between Electrify America and AAA of
      Northern California, Nevada &amp; Utah (“AAA”) for AAA’s Gig Ops Charging program. By clicking “Submit” below, you
      agree to the following terms and conditions regarding your use of the Gig Ops Charging program for charging
      sessions made on Electrify America’s network of electric vehicle charging stations under this account:
    </p>
    <p>
      After signing up, you can initiate a charging session under this plan at any Electrify America charging station
      exclusively by using the Electrify America app “Swipe to start” or NFC Pass feature with the Gig Ops Charging plan
      selected.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS CHARGING PLAN:</strong>
    </p>
    <ul>
      <li>
        You may
        <strong>only</strong> use this plan for the vehicle(s) that have been expressly approved and authorized to
        receive these charging benefits. &nbsp;
        <strong> You may not use this plan for any vehicle outside of the Gig Ops Charging program, </strong>, whether
        owned by you or others.
      </li>
      <li>
        <strong>
          This plan only applies to DC fast charging sessions at Electrify America charging stations and will not be
          applied to any other type of charging session or location.
        </strong>
      </li>
      <li>
        You must follow all product, vehicle, safety, and technical documentation included with your vehicle when
        charging at an Electrify America charging station.
      </li>
    </ul>
    <p>
      The benefits provided under this charging plan are not returnable or redeemable for any cash value, and may not be
      sold, loaned, or otherwise distributed to any third party for any reason.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at&nbsp;
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link>, and
      that your use of the Electrify America mobile app is subject to the Terms of Use accessible by selecting
      <strong>“Legal”</strong> in the Account Management portion of the mobile app (accessed by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map), then selecting
      <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &amp;
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link> Please read these Terms
      of Use documents carefully, as they govern all use of Electrify America’s app and other services, including your
      use under this plan.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services, and when
      charging your vehicle at Electrify America charging stations. Without limiting anything in these terms, the
      Electrify America Terms of Use, or Electrify America’s Privacy Policy,
      <strong>
        you also agree to follow all additional instructions, directions, and policies provided to you by AAA regarding
        your participation in the Gig Ops Charging program and your access to this charging plan.
      </strong>
    </p>

    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this promotion; or (c) have engaged in any
      illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify America
      services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
  </div>
</template>

<script>
export default {
  name: 'gigDisclosure',
  metaInfo: {
    title: 'Gig Disclosure | Electrify America',
    meta: [
      {
        name: 'description',
        content: 'Gig Disclosure',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/gig-disclosure/' }],
  },
};
</script>
